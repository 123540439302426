import React from "react";
import { Script, ScriptStrategy } from "gatsby"

const Scripts = () => {
  return (
    <>
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=G-DETTSK3CLK`}
          strategy={ScriptStrategy.idle}>
        </Script>

    </>
  );
}

export default Scripts; 
import React from "react";

import Scripts from "./src/scripts";

// Wraps every page in a component
export const wrapPageElementShared = (props) => {
    return (
        <>
            {props.element}
            <Scripts />
        </>
    );
};